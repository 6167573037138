// contexts/AuthContext.js
import React, {createContext, useCallback, useEffect, useState} from 'react';
import {axiosInstance, clearAuthHeader, initToken} from "config/axiosConfig";
import {postData} from "utils/TransactionUtil";

import Loading from "config/Loading";

export const AuthContext = createContext(); // Context 생성

// Context의 Provider 컴포넌트
export const AuthProvider = (props) => {

    const [isLoggedIn, setIsLoggedIn] = useState(null);     // 로그인 상태
    const [loading, setLoading] = useState(true);   // 로딩 상태 관리
    const [mngrInfo, setMngrInfo] = useState(null);         // 사용자 정보
    const [personInfo, setPersonInfo] = useState(null);     // 환자 정보


    /* 자동 로그인 하기 */
    useEffect(() => {

        const checkLoginStatus = async () => {
            try {
                await initToken(); // 로컬스토리지에서 토큰을 가져와 해더에 셋팅

                // 해더에 토큰 여부 체크 > 토큰을 이용하여 유저 정보를 가져옴
                if (axiosInstance.defaults.headers.common.Authorization) {
                    const data = await postData("/getMngrInfo");
                    if (data) {
                        login(data); // 권한 컨텍스트 로그인 처리
                    } else {
                        console.log("아이디와 비밀번호를 확인해 주세요.");
                    }
                }
            } catch (error) {
                console.error("자동 로그인 오류:", error);
            } finally {
                setLoading(false); // 로딩 종료
            }
        };

        checkLoginStatus();
    }, []);

    /* = 로그인 관련 ================================================*/
    const login = useCallback((mngrData) => {
        setIsLoggedIn(true);
        setMngrInfo(mngrData);
        timeReset(); // 타임 아웃 정보 초기화
    }, []);

    // 로그아웃
    const logout = useCallback((showAlert = false) => {
        setIsLoggedIn(false);
        setMngrInfo(null);
        clearAuthHeader(); // 헤더 초기화
        localStorage.clear(); // 로컬 스토리지 초기화
        sessionStorage.clear(); // 세션 스토리지 초기화

        if (showAlert) {
            alert('로그인이 만료되었습니다.');
            window.location.href = '/'; // 메인 페이지로 리다이렉트
        }
    }, []);

    // 환자 인증 시작
    const certStart = useCallback((personData) => {
        timeReset(); // 타임 아웃 정보 초기화
        setPersonInfo(personData);
    }, []);

    // 환자 인증 종료
    const certEnd = useCallback(() => {

        timeReset(); // 타임 아웃 정보 초기화
        localStorage.removeItem('timeLeft');
        setPersonInfo(null);

        window.location.href = '/'; // 메인 페이지로 리다이렉트
    }, []);


    /* = 로그아웃 타이머 관련 설정 ================================================*/
    const initialTime = 18 * 60; // 카운트다운 초기값 (18분)
    const storedTimeLeft = localStorage.getItem('timeLeft');
    const [timeLeft, setTimeLeft] = useState(storedTimeLeft ? parseInt(storedTimeLeft, 10) : initialTime);

    // 로그인 기준 시간을 초기화 함. (30분)
    const timeReset = () => {
        setTimeLeft(initialTime);
        //localStorage.setItem('timeLeft', initialTime); // 타이머 초기값 로컬 스토리지에 저장
    };

    /* 시간이 1초마다 감소
       환자 정보가 있을경우 카운트가 시작된다.
    * */
    useEffect(() => {
        if (!personInfo) return; // 로그인 정보가 없을 경우 리턴

        const interval = setInterval(() => {
            setTimeLeft((prevTime) => {
                if (prevTime <= 1) {
                    clearInterval(interval);
                    certEnd();
                    return 0;
                }
                const newTime = prevTime - 1;
                //localStorage.setItem('timeLeft', newTime); // 남은 시간 로컬 스토리지에 저장
                return newTime;
            });
        }, 1000);

        return () => clearInterval(interval);
    }, [personInfo, certEnd]);



    // Context의 값을 제공할 Provider를 반환
    return (
        <AuthContext.Provider
            value={{
                isLoggedIn,
                timeLeft,
                mngrInfo,
                personInfo,
                onLogin: login,
                onLogout: logout,
                onCertStart: certStart,
                onCertEnd : certEnd,
                onTimeReset: timeReset
            }}
        >
            {loading ? <Loading /> : props.children}
        </AuthContext.Provider>
    );
};