// src/routes.js
import React from 'react';
import {BrowserRouter as Router, Navigate, Route, Routes} from 'react-router-dom';

import COM101F1 from "pages/COM/COM101F1";

import COM901F1 from "pages/COM/COM901F1";

import COM001F1 from "pages/COM/COM001F1";
import COM002F1 from "pages/COM/COM002F1";
import Loading from "config/Loading";

const MainRoutes = () => {

    return (
        <Router>
            {/*<COM001F1/>  /!*Header*!/*/}
            <Routes>
                {/*<Route path="/" element={<COM101F1/>}/>*/}
                <Route path="/" element={<COM901F1/>}/>
                <Route path="*" element={<Navigate to="/"/>}/>
            </Routes>
            {/*<COM002F1/>  /!*Footer*!/*/}
            <Loading />
        </Router>
    );
};

export default MainRoutes;