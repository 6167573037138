// utils/TransactionUtil.jsx
import { axiosInstance } from "config/axiosConfig";

// 공통 요청 함수 (GET/POST 분기 처리)
async function requestData(method, url = "", params = {}) {
    await new Promise((r) => setTimeout(r, 500)); // 의도적 지연

    try {
        const config = method === "get" ? { params } : params;
        const response = await axiosInstance[method](url, config);

        if (response.status === 200) {
            return response.data;
        } else {
            console.error(`오류 발생: 상태 코드 ${response.status}`);
            throw new Error(response.statusText || "Unexpected error occurred");
        }
    } catch (error) {
        console.error(`HTTP ${method.toUpperCase()} 요청 실패:`, error);
        throw error; // 호출한 쪽에서 에러 핸들링 가능하도록 재전달
    }
}

// GET 요청
export async function getData(url = "", params = {}) {
    return requestData("get", url, params);
}

// POST 요청
export async function postData(url = "", params = {}) {
    return requestData("post", url, params);
}