// App.js

import React from 'react';
import MainRoutes from 'config/MainRoutes';
import './App.css';

const App = () => {
    return (
        <MainRoutes/>
    );
};

export default App;