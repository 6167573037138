// config/axiosConfig.jsx
import axios from 'axios';

export const axiosInstance = axios.create({
    //baseURL: "http://localhost:8080",
    timeout: 3000,
    headers: {
        "Cache-Control": "no-cache",
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
    },
    responseType: "json",
});

/* = 에러 처리 인터셉터 ======================== */
axiosInstance.interceptors.response.use(
    (response) => response, // 성공 응답 처리
    async (error) => {
        const originalRequest = error.config; // 원래 요청상태 저장

        //로그아웃은 완료 처리 함.
        if(originalRequest.url === "/actionLogout") {
            console.log("오류가 발생하였습니다. 관리자에게 문의 바랍니다.("+error.response.status+")");
            logout();
        }

        const status = error.response?.status;

        // 401 에러(UNAUTHORIZED - 만료 토큰으로 처리) && 원래 요청이 다시 요청된 것이 아니면
        if (status === 401 && !originalRequest._retry) {
            originalRequest._retry = true; // 중복 실행 방지

            // refreshToken을 사용하여 accessToken 갱신
            const refreshToken = getToken("refresh");

            // 로그인 경우 오류 처리
            if(originalRequest.url === "/actionLogin") {
                alert("아이디와 비밀번호를 확인해 주세요..");
                return;
            }else if(originalRequest.url === "/getMngrInfo") {
                console.log("로그인이 만료되었습니다. 다시 로그인해주세요.");
                return logout();
            }else if (!refreshToken) { // refreshToken이 없는 경우 로그아웃 처리
                return logout("로그인이 만료되었습니다. 다시 로그인해주세요.");
            }

            //로그인 만료로 토큰 재 발행 을 위한 재발행 실시
            return reissueToken(refreshToken, originalRequest);
        }


        /*
        406: 권한없음 - 중복로그인
        403: 서버접근거부 - 블랙리스트 처리된 사용자
        405: 서버오류 - 서버 리부팅에 따른 처리 필요
        */
        if ((status === 406 || status === 403) && !originalRequest._retry) {
            originalRequest._retry = true;

            const msg =
                status === 406 ? "중복 로그인으로 다시 로그인 하시기 바랍니다.":
                    status === 403 ? "로그인 만료로 다시 로그인 하시기 바랍니다.":
                        `로그인 만료 (${status})`;
            logout(msg);
        }

        if (status === 405 && !originalRequest._retry) { // 서버 오류   //서버 리부팅에 따른 처리 필요
            originalRequest._retry = true;

            if(originalRequest.url !== "/getMngrInfo")
                alert("로그인 만료로 다시 로그인 하시기 바랍니다.("+status+")");

            logout();
        }

        if ((status >= 400 || status < 500) && !originalRequest._retry) {
            logout("로그인 만료로 다시 로그인 하시기 바랍니다...("+status+")" );
        }

        return Promise.reject(error);
    }
);

/* 후처리 */
const reissueToken = (token, originalRequest) => {

    // 리프레쉬 토큰을 해더에 교체 진행 한다.
    axiosInstance.defaults.headers.common["Authorization"] = `Bearer ${token}`;

    // 토큰 재발행 api 요청
    return axiosInstance({
        url: "/reissueToke",
        method: "POST"
    }).then((res) => {
        // 정상 요청되면 새로 받은 토큰 저장
        if (res.status === 200) {

            setAuthHeader(res.accessToken, res.refreshToken); // 헤더에 토큰 생성
            //localStorage.setItem("lginId", res.lginId);

            // 원래 요청 재시도
            return axiosInstance(originalRequest);
        } else {
            console.log(res);
        }
    }).catch((err) => {
        console.log(err);
    });
}




/* = 토큰 유틸  =====================================*/
export const setAuthHeader = async (accessToken, refreshToken) => {
    axiosInstance.defaults.headers.common["Authorization"] = `Bearer ${accessToken}`;
    localStorage.setItem("@access_token", accessToken);
    localStorage.setItem("@refresh_token", refreshToken);
};

export const clearAuthHeader = () => {
    axiosInstance.defaults.headers.common.Authorization = undefined
    localStorage.removeItem("@access_token");
    localStorage.removeItem("@refresh_token");
};

export const getToken = (type) => localStorage.getItem(`@${type}_token`) || null;

export const initToken = async () => {

    const accessToken = getToken("access");
    const refreshToken = getToken("refresh");

    if(accessToken || refreshToken){
        await setAuthHeader(accessToken, refreshToken);
    }else{
        logout(false);
    }
};



const logout = (alertMsg = null) => {
    // setIsLoggedIn(false);
    // setUserInfo(null);
    clearAuthHeader(); // 헤더 초기화
    localStorage.clear(); // 로컬 스토리지 초기화
    sessionStorage.clear(); // 세션 스토리지 초기화

    if (alertMsg) {
        alert(alertMsg);
        window.location.href = '/'; // 메인 페이지로 리다이렉트
    }
};