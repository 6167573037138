import React from "react";
import styles from "./COM901F1.module.scss";

// 이미지 배열 (6개의 이미지 경로 또는 URL)

const COM901F1 = () => {

    return (
        <div className={styles.root_wrap}>
            <div className={styles.main_wrap}>
                준비중입니다.
            </div>
        </div>
    );
};

export default COM901F1;


