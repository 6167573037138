import React, {useEffect, useState} from "react";
import { PulseLoader } from "react-spinners";
import styled from "styled-components";
import {axiosInstance} from "./axiosConfig";

const StyledDivCont = styled.div`
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 99;
    //background-color: rgba(0, 0, 0, 0.05);

    display: flex;
    align-items: center;
    justify-content: center;
`

const Loading = () => {

    const [loading, setLoading] = useState(false);

    useEffect(()=>{
        //axios 호출시 인터셉트
        axiosInstance.interceptors.request.use(function (config) {
            setLoading(true);
            return config
        },function (error) {
            return Promise.reject(error);
        });
        //axios 호출 종료시 인터셉트
        axiosInstance.interceptors.response.use(function (response) {
            setLoading(false);
            return response;
        }, function (error) {
            setLoading(false);
            return Promise.reject(error);
        });
    },[]);

    return (
        loading && (
        <StyledDivCont>
            <PulseLoader color="skyblue" />
        </StyledDivCont>
        )
    );
};

export default Loading;